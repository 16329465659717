<template>
    <div class="uk-grid-small" uk-grid>
        <div class="uk-width-1-1">
            <div class="uk-padding-small uk-border-rounded myrobin-background-blue">
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-1">
                        <label for="additionalQuestionCategory" class="uk-form-label myrobin-color-white">Select Category</label>
                        <multiselect
                            v-model="selectedAdditionalQuestionCategory"
                            label="name"
                            track-by="name"
                            open-direction="bottom"
                            placeholder="Select a category"
                            class="myrobin-background-white uk-border-rounded"
                            :multiple="false"
                            :close-on-select="true"
                            :options="additionalQuestionCategoryOptions"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <label for="additionalQuestionTemplate" class="uk-form-label myrobin-color-white">Select Template</label>
                        <multiselect
                            v-model="selectedAdditionalQuestionTemplate"
                            label="name"
                            track-by="name"
                            open-direction="bottom"
                            placeholder="Select a template"
                            class="myrobin-background-white uk-border-rounded"
                            :multiple="false"
                            :close-on-select="true"
                            :options="additionalQuestionTemplateOptions"
                            :disabled="!selectedAdditionalQuestionCategory"
                        />
                    </div>
                    <div class="uk-width-1-1">
                        <div class="uk-flex uk-flex-middle uk-flex-right">
                            <button
                                type="button"
                                class="uk-flex uk-flex-center uk-flex-middle myrobin-preview-template-button"
                                :class="[!selectedAdditionalQuestionTemplate ? 'myrobin-color-silver myrobin-cursor-not-allowed' : 'myrobin-color-white myrobin-cursor-pointer']"
                                :disabled="!selectedAdditionalQuestionTemplate"
                                @click="togglePreviewTemplate"
                            >
                                <p class="uk-margin-remove">Preview Template</p>
                                <img 
                                    v-if="selectedAdditionalQuestionTemplate"
                                    :src="`${images}/expand-more-white.svg`" 
                                    alt="Expand more icon"
                                    loading="lazy"
                                    class="uk-margin-small-left"
                                    :class="[previewTemplate ? 'myrobin-transform-180deg' : 'myrobin-transform-0deg']"
                                />
                                <img 
                                    v-else
                                    :src="`${images}/expand-more-silver.svg`" 
                                    alt="Expand more icon"
                                    loading="lazy"
                                    class="uk-margin-small-left"
                                />
                            </button>
                        </div>
                    </div>
                    <div v-if="previewTemplate" class="uk-width-1-1">
                        <div class="myrobin-background-white uk-border-rounded uk-padding-small">
                            <div class="uk-grid-small" uk-grid>
                                <div 
                                    v-for="(questionTemplate, indexQuestionTemplate) in questionTemplates"
                                    :key="indexQuestionTemplate"
                                    class="uk-width-1-1 uk-display-block"
                                >
                                    <template v-if="questionTemplate.type === questionType.short_answer">
                                        <label class="uk-form-label">
                                            <p class="uk-margin-remove">
                                                {{ questionTemplate.question || '-' }}
                                                <span v-if="questionTemplate.required" class="uk-text-danger">*</span>
                                            </p>
                                        </label>
                                        <p class="uk-margin-small-bottom uk-margin-remove-top text-description uk-text-break">{{ questionTemplate.description }}</p>
                                        <textarea
                                            name="description"
                                            class="uk-input uk-border-rounded uk-input-disabled-override"
                                            placeholder="Short answer"
                                            disabled
                                        />
                                    </template>
                                    <template v-else-if="questionTemplate.type === questionType.single_choice">
                                        <label class="uk-form-label uk-margin-small-bottom">
                                            <p class="uk-margin-remove-top uk-margin-small-bottom">
                                                {{ questionTemplate.question || '-' }}
                                                <span v-if="questionTemplate.required" class="uk-text-danger">*</span>
                                            </p>
                                        </label>
                                        <div v-if="questionTemplate.options.length > 0" class="uk-grid-small" uk-grid>
                                            <div 
                                                v-for="(optionQuestionTemplate, indexOptionQuestionTemplate) in questionTemplate.options"
                                                :key="indexOptionQuestionTemplate"
                                                class="uk-width-1-1"
                                            >
                                                <div class="uk-flex uk-flex-left uk-flex-middle uk-width-2-3">
                                                    <input type="radio" class="uk-radio uk-margin-small-right" disabled />
                                                    <input 
                                                        type="text" 
                                                        class="uk-input uk-input-disabled-override uk-border-rounded"
                                                        :placeholder="`${optionQuestionTemplate}`"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="questionTemplate.type === questionType.multi_choices">
                                        <label class="uk-form-label">
                                            <p class="uk-margin-remove-top uk-margin-small-bottom">
                                                {{ questionTemplate.question || '-' }}
                                                <span v-if="questionTemplate.required" class="uk-text-danger">*</span>
                                            </p>
                                        </label>
                                        <div v-if="questionTemplate.options.length > 0" class="uk-grid-small" uk-grid>
                                            <div 
                                                v-for="(optionQuestionTemplate, indexOptionQuestionTemplate) in questionTemplate.options"
                                                :key="indexOptionQuestionTemplate"
                                                class="uk-width-1-1"
                                            >
                                                <div class="uk-flex uk-flex-left uk-flex-middle uk-width-2-3">
                                                    <input type="checkbox" class="uk-checkbox uk-border-rounded uk-margin-small-right" disabled />
                                                    <input 
                                                        type="text" 
                                                        class="uk-input uk-border-rounded uk-input-disabled-override"
                                                        :placeholder="`${optionQuestionTemplate}`"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <label for="startDate" class="uk-form-label uk-width-1-1">
                                            <p class="uk-margin-remove-top uk-margin-small-bottom">
                                                {{ questionTemplate.question || '-' }}
                                                <span v-if="questionTemplate.required" class="uk-text-danger">*</span>
                                            </p>
                                        </label>
                                        <div class="uk-width-2-3 uk-inline">
                                            <datetime
                                                input-class="uk-input uk-border-rounded uk-input-disabled-override"
                                                type="date"
                                                placeholder="DD/MM/YYYY"
                                                disabled
                                            />
                                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="questions.length > 0" class="uk-width-1-1">
            <div 
                v-for="(question, indexQuestion) in questions"
                :key="indexQuestion"
                class="uk-grid-small" 
                uk-grid
            >
                <div class="uk-width-2-3">
                    <label class="uk-form-label">Question {{ (indexQuestion + questionTemplates.length) + 1 }}</label>
                    <input 
                        type="text" 
                        class="uk-input uk-border-rounded"
                        placeholder="Type question here..."
                        v-model="question.question"
                    />
                    <span v-if="questionErrors[indexQuestion] && questionErrors[indexQuestion].question" class="uk-text-small uk-text-danger">
                        {{ questionErrors[indexQuestion].question }}
                    </span>
                </div>
                <div class="uk-width-1-3">
                    <label class="uk-form-label">Answer Type</label>
                    <select class="uk-select uk-border-rounded" v-model="question.type" @change="(question.options = [])">
                        <option 
                            v-for="(type) in types"
                            :key="type.value"
                            :value="type.value"
                        >{{ type.label }}</option>
                    </select>
                </div>
                <div v-if="question.type === questionType.short_answer" class="uk-width-2-3 uk-margin-small-bottom">
                    <textarea
                        v-model="question.description"
                        name="description"
                        class="uk-input uk-border-rounded uk-margin-small-bottom min-height-96px"
                        placeholder="Description (Optional)"
                        @input="(e) => descriptionChange(indexQuestion)(e)"
                    />
                    <textarea type="text" class="uk-input uk-border-rounded uk-input-disabled-override" style="margin-top: 4px;" placeholder="Short answer" disabled />
                </div>
                <div v-else-if="question.type === questionType.date" class="uk-width-1-1">
                    <div class="uk-width-2-3 uk-inline">
                        <datetime
                            input-class="uk-input uk-border-rounded uk-input-disabled-override"
                            type="date"
                            placeholder="DD/MM/YYYY"
                            disabled
                        />
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                    </div>
                </div>
                <div v-else-if="question.type === questionType.multi_choices" class="uk-width-1-1">
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-1">
                            <label class="uk-form-label">Options</label>
                            <div v-if="questionErrors[indexQuestion] && questionErrors[indexQuestion].options" class="uk-width-1-1">
                                <span class="uk-text-small uk-text-danger">
                                    {{ questionErrors[indexQuestion].options }}
                                </span>
                            </div>
                        </div>
                        <div v-if="question.options.length > 0" class="uk-width-1-1">
                            <div class="uk-grid-small" uk-grid>
                                <div v-for="(_, indexOption) in question.options" :key="indexOption" class="uk-width-1-1">
                                    <div class="uk-flex uk-flex-left uk-flex-middle">
                                        <input type="radio" class="uk-radio uk-margin-small-right" disabled />
                                        <input 
                                            type="text" 
                                            class="uk-input uk-border-rounded uk-margin-small-right uk-width-1-2"
                                            :placeholder="`Option ${indexOption + 1}`"
                                            v-model="question.options[indexOption]"
                                        />
                                        <button
                                            type="button"
                                            class="uk-flex uk-flex-center uk-flex-middle"
                                            @click="deleteOption(indexQuestion, indexOption)"
                                        >
                                            <img 
                                                class="uk-margin-small-left photo" 
                                                :data-src="`${images}/trash.png`" 
                                                alt="icon add" 
                                                uk-img 
                                                width="20" 
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <button
                                type="button"
                                class="uk-flex uk-flex-center uk-flex-middle"
                                @click="addOption(indexQuestion)"
                            >
                                <img 
                                    :data-src="`${images}/plus-single.svg`" 
                                    alt="icon add" 
                                    uk-img 
                                    class="photo uk-margin-small-right" 
                                />
                                <p class="uk-margin-remove uk-text-primary">Add Option</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else class="uk-width-1-1">
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-1">
                            <label class="uk-form-label">Options</label>
                            <div v-if="questionErrors[indexQuestion] && questionErrors[indexQuestion].options" class="uk-width-1-1">
                                <span class="uk-text-small uk-text-danger">
                                    {{ questionErrors[indexQuestion].options }}
                                </span>
                            </div>
                        </div>
                        <div v-if="question.options.length > 0" class="uk-width-1-1">
                            <div class="uk-grid-small" uk-grid>
                                <div v-for="(_, indexOption) in question.options" :key="indexOption" class="uk-width-1-1">
                                    <div class="uk-flex uk-flex-left uk-flex-middle">
                                        <input type="checkbox" class="uk-checkbox uk-border-rounded uk-margin-small-right" disabled />
                                        <input 
                                            type="text" 
                                            class="uk-input uk-border-rounded uk-margin-small-right uk-width-1-2"
                                            :placeholder="`Option ${indexOption + 1}`"
                                            v-model="question.options[indexOption]"
                                        />
                                        <button
                                            type="button"
                                            class="uk-flex uk-flex-center uk-flex-middle"
                                            @click="deleteOption(indexQuestion, indexOption)"
                                        >
                                            <img 
                                                class="uk-margin-small-left photo" 
                                                :data-src="`${images}/trash.png`" 
                                                alt="icon add" 
                                                uk-img 
                                                width="20" 
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1">
                            <button
                                type="button"
                                class="uk-flex uk-flex-center uk-flex-middle"
                                @click="addOption(indexQuestion)"
                            >
                                <img 
                                    :data-src="`${images}/plus-single.svg`" 
                                    alt="icon add" 
                                    uk-img 
                                    class="photo uk-margin-small-right" 
                                />
                                <p class="uk-margin-remove uk-text-primary">Add Option</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <label><input class="uk-checkbox uk-border-rounded" type="checkbox" v-model="question.required"> Required</label>
                        <button
                            type="button"
                            class="uk-flex uk-flex-center uk-flex-middle"
                            @click="deleteQuestion(indexQuestion)"
                        >
                            <img 
                                class="uk-margin-small-right photo" 
                                :data-src="`${images}/trash.png`" 
                                alt="icon add" 
                                uk-img 
                                width="20" 
                            />
                            <p class="uk-margin-remove uk-text-danger">Delete</p>
                        </button>
                    </div>
                    <hr class="uk-margin-small" />
                </div>
            </div>
        </div>
        <div class="uk-width-1-1">
            <button 
                type="button"
                class="uk-button uk-button-default uk-border-rounded uk-flex uk-flex-center uk-flex-middle uk-width-1-1 myrobin-add-question-button"
                @click="addQuestion"
            >
                <img 
                    :data-src="`${images}/plus-circle.svg`" 
                    alt="icon add" 
                    loading="lazy" 
                    class="photo uk-margin-small-right"
                    uk-img 
                />
                <p class="uk-margin-remove">Add Question</p>
            </button>
        </div>
        <div class="uk-width-1-1">
            <div class="uk-flex uk-flex-column">
                <span>Use this additional question(s) for:</span>
                <label><input class="uk-checkbox" type="checkbox" v-model="formData.question_usage.default"> Normal flow</label>
                <label><input class="uk-checkbox" type="checkbox" v-model="formData.question_usage.auto_assign"> Autoassign Register</label>
                <label><input class="uk-checkbox" type="checkbox" v-model="formData.question_usage.auto_assign_unreg"> Autoassign Unregister</label>
                <label><input class="uk-checkbox" type="checkbox" v-model="formData.question_usage.migration"> Migrasi</label>
                <span v-if="questionUsageError" class="uk-text-small uk-text-danger uk-display-block">{{ questionUsageError }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Datetime } from 'vue-datetime';
import { PREFIX_IMAGE } from "@/utils/constant";
import { 
    // notificationSuccess, 
    notificationDanger, 
    // notificationDangerCustom 
} from '@/utils/notification';

export default {
    components: {
        Multiselect: () => import('vue-multiselect'),
        Datetime
    },
    props: {
        formData: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    data(){
        return {
            images: PREFIX_IMAGE,
            questionErrors: {},
            questionUsageError: null,
            types: [
                {
                    label: "Short Answer",
                    value: 1
                },
                {
                    label: "Single Choice",
                    value: 2
                },
                {
                    label: "Multiple Choice",
                    value: 3
                },
                {
                    label: "Date",
                    value: 4
                }
            ],
            additionalQuestionCategoryOptions: [],
            additionalQuestionTemplateOptions: [],
            selectedAdditionalQuestionCategory: null,
            selectedAdditionalQuestionTemplate: null,
            previewTemplate: false,
            questionTemplates: [],
            questions: [],
            questionType: {
                short_answer: 1,
                single_choice: 2,
                multi_choices: 3,
                date: 4
            }
        };
    },
    watch: {
        "$props.formData.question_usage": {
            deep: true,
            handler() {
                this.questionUsageError = null;

                if (
                    !this.$props.formData.question_usage.default &&
                    !this.$props.formData.question_usage.auto_assign &&
                    !this.$props.formData.question_usage.auto_assign_unreg &&
                    !this.$props.formData.question_usage.migration
                ) {
                    this.questionUsageError = "The question usage is required";
                }

                this.validate();
            },
        },
        async selectedAdditionalQuestionCategory(val) {
            try {
                if (val && val._id) {
                    await this.setAdditionalQuestionTemplateOptions(val._id);
                    this.selectedAdditionalQuestionTemplate = null;
                    this.previewTemplate = false;
                    this.questionTemplates = [];
                    this.questions = [];
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        selectedAdditionalQuestionTemplate(val) {
            const mapQuestions = this.questions.map((question) => ({
                question: question.question,
                type: question.type,
                options: question.options,
                required: question.required,
                description: question.description
            }));

            if (val && Array.isArray(val.questions) && val.questions.length > 0) {
                this.previewTemplate = true;
                this.questionTemplates = val.questions;
                const mapQuestionTemplate = val.questions.map((question) => ({
                    category_template_id: this.selectedAdditionalQuestionCategory._id,
                    template_id: this.selectedAdditionalQuestionTemplate._id,
                    _id: question._id,
                    question: question.question,
                    type: question.type,
                    options: question.options,
                    required: question.required
                }));
                
                this.$props.formData.additional_questions = [...mapQuestionTemplate, ...mapQuestions];
            } else {
                this.previewTemplate = false;
                this.questionTemplates = [];
                this.$props.formData.additional_questions = [...mapQuestions];
            }
        },
        questions: {
            deep: true,
            handler() {
                this.questionErrors = {};
                this.questions.forEach((question, indexQuestion) => this.validateQuestion(question, indexQuestion));
                this.validate();

                const mapQuestions = this.questions.map((question) => ({
                    question: question.question,
                    type: question.type,
                    options: question.options,
                    required: question.required,
                    description: question.description
                }));
                
                if (this.questionTemplates.length > 0) {
                    const mapQuestionTemplate = this.questionTemplates.map((question) => ({
                        category_template_id: this.selectedAdditionalQuestionCategory._id,
                        template_id: this.selectedAdditionalQuestionTemplate._id,
                        _id: question._id,
                        question: question.question,
                        type: question.type,
                        options: question.options,
                        required: question.required
                    }));
                    this.$props.formData.additional_questions = [...mapQuestionTemplate, ...mapQuestions];
                } else {
                    this.$props.formData.additional_questions = [...mapQuestions];
                }
            }
        }
    },
    async mounted() {
        try {
            await this.setAdditionalQuestionCategoryOptions();
            this.fillQuestion();
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        ...mapActions({
            getCategoryList: 'additional_question_template_category/getCategoryList',
            getTemplateList: 'additional_question_template/getTemplateList',
        }),
        async setAdditionalQuestionCategoryOptions() {
            try {
                this.isLoadingSelectedAdditionalQuestionCategory = true;
                const response = await this.getCategoryList();
                if (response && response.status === 'OK' && response.result) {
                    const result = response.result || [];
                    this.additionalQuestionCategoryOptions = result;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.isLoadingSelectedAdditionalQuestionCategory = false;
            }
        },
        async setAdditionalQuestionTemplateOptions(categoryId) {
            const response = await this.getTemplateList({ categoryId });
            if (response && response.status === 'OK' && response.result) {
                const result = response.result || [];
                this.additionalQuestionTemplateOptions = result;
            }
        },
        togglePreviewTemplate() {
            this.previewTemplate = !this.previewTemplate;
        },
        addQuestion() {
            this.questions.push({
                type: 1,
                question: '',
                options: [],
                required: false,
                description: ''
            });
        },
        deleteQuestion(indexQuestion) {
            this.questions.splice(indexQuestion, 1);
        },
        addOption(indexQuestion) {
            this.questions[indexQuestion].options.push('');
        },
        deleteOption(indexQuestion, indexOption) {
            this.questions[indexQuestion].options.splice(indexOption, 1);
        },
        validateQuestion(question, key) {
            this.questionErrors[key] = {};

            if (!question.question || question.question == '') {
                this.questionErrors[key] = { question: "The question is required" };
            }

            if (question.type !== 1 && question.type !== 4) {
                if (question.options.length <= 0) {
                    this.questionErrors[key] = { ...this.questionErrors[key], options: "You have to add at least one option" };
                } else {
                    for (let i = 0; i < question.options.length; i++) {
                        if (!question.options[i] || question.options[i] == '') {
                            this.questionErrors[key] = { ...this.questionErrors[key], options: `The option ${i + 1} is required` };
                            break;
                        }
                    }
                }
            }

        },
        validate() {
            this.$emit("valid");

            if (this.questionUsageError) {
                return this.$emit("inValid");
            }

            for (let i = 0; i < Object.keys(this.questionErrors).length; i++) {
                if (Object.keys(this.questionErrors[i]).length > 0) {
                    this.$emit("inValid");
                    break;
                } else {
                    this.$emit("valid");
                }
            }
        },
        fillQuestion() {
            if (
                this.$props.formData &&
                Array.isArray(this.$props.formData.additional_questions) &&
                this.$props.formData.additional_questions?.length > 0
            ) {
                const questionTemplates = this.$props.formData.additional_questions.filter((question) => question && question.category_template_id && question.template_id);
                const questions = this.$props.formData.additional_questions.filter((question) => question && !question.category_template_id && !question.template_id);

                if (Array.isArray(questionTemplates) && questionTemplates.length > 0) {
                    const categoryTemplateId = questionTemplates[0]?.category_template_id;
                    const templateId = questionTemplates[0]?.template_id;

                    this.selectedAdditionalQuestionCategory = categoryTemplateId;
                    this.selectedAdditionalQuestionTemplate = templateId;

                    this.questionTemplates = questionTemplates;
                } else {
                    this.questionTemplates = [];
                }

                if (Array.isArray(questions) && questions.length > 0) {
                    this.questions = questions;
                } else {
                    this.questions = [];
                }
            }
        },
        descriptionChange(index) {
            return (e) => {
                if (
                    (index !== undefined && !e) && // from mounted modal
                    !e.target // from input handler on textarea
                ) {
                    return;
                }

                const element = e.target || e;
                element.style.height = 'auto';
                if (element.scrollHeight > element.clientHeight) {
                    element.style.height = `${element.scrollHeight + 4}px`;
                }
            };
        }
    }
};
</script>

<style scoped>
.myrobin-background-blue {
  background: #0275D8;
}

.myrobin-color-white {
  color: #FFFFFF;
}

.myrobin-add-question-button {
  border: 1px solid #0275D8;
  font-size: 16px;
  color: #0275D8;
}

.myrobin-preview-template-button {
  font-size: 16px;
  font-weight: 600;
}

.myrobin-color-silver {
    color: #D0D0D0;
}

.myrobin-background-white {
    background: #FFFFFF;
}

.myrobin-cursor-not-allowed {
    cursor: not-allowed;
}

.myrobin-cursor-pointer {
    cursor: pointer;
}

.myrobin-transform-180deg {
    transform: rotate(180deg);
    transition: all 0.2s;
}

.myrobin-transform-0deg {
    transform: rotate(0deg);
    transition: all 0.2s;
}

.text-description {
    font-size: 14px;
    color: #9E9E9E;
}

.min-height-96px {
    min-height: 96px;
}

textarea[type="text"], textarea[name="description"] {
    line-height: 24px;
    padding: 6px 10px;
}
</style>
